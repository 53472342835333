<template>
  <div class="account">
    <PageSubHeader :pageSubHeader="pageSubHeader" />

    <main>
      <section id="account_apply">
        <div class="w1300 layout_container">

          <!-- 內頁主標題 -->
          <div class="main_title_box">
            <router-link
              to="/about/according"
              class="main_title_btn main_title_btn-evaluate small"
              target="_blank"
            >
              評核實施辦法
            </router-link>
            <router-link
              to="/account/search/profession"
              class="main_title_btn main_title_btn-apply small"
              target="_blank"
            >
              專科申請查詢
            </router-link>
            <h3 class="main_title txt-bold">專科醫師申請</h3>
            <div class="img_box">
              <img src="@/statics/img/index/icon_title2.svg" alt="">
            </div>
          </div>

          <!-- form area -->
          <div class="form_box">
            <form id="form_account_apply" action="" method="post" @submit.prevent>

              <!-- 基本資料 -->
              <div class="form_description">
                <div class="main_description_box mg-tb-10">
                  <h4 class="main_description_title txt-light_green txt-bold">基本資料</h4>
                </div>
              </div>
              <div class="form_interactive">
                <div class="half">
                  <div class="fixed_info w33">
                    <p class="fixed_info_title">姓名</p>
                    <p class="fixed_info_content">{{ user.name }}</p>
                  </div>
                  <div class="fixed_info w33">
                    <p class="fixed_info_title">會員編號</p>
                    <p class="fixed_info_content">{{ user.memberId }}</p>
                  </div>
                  <div class="fixed_info w33">
                    <p class="fixed_info_title">性別</p>
                    <p class="fixed_info_content">{{ user.gender }}</p>
                  </div>
                </div>
                <div class="half">
                  <div class="fixed_info w50">
                    <p class="fixed_info_title">出生年月日</p>
                    <p class="fixed_info_content">{{ user.birthday }}</p>
                  </div>
                  <div class="fixed_info w50">
                    <p class="fixed_info_title">身分證字號</p>
                    <p class="fixed_info_content">{{ user.id }}</p>
                  </div>
                </div>
                <TextInput
                  class="w50"
                  v-model.trim="user.mobile"
                  :item="inputFormat.mobileInput"
                  :rules="[
                    val => !!val || '必填欄位！',
                    [/^09\d{8}$/, '手機格式錯誤']
                  ]"
                  :maxlength="10"
                />
                <div class="half">
                  <TextInput
                    class="w50"
                    v-model.trim="user.telePublic"
                    :item="inputFormat.telePublicInput"
                    :maxlength="11"
                  />
                  <TextInput
                    class="w50"
                    v-model.trim="user.teleHome"
                    :item="inputFormat.teleHomeInput"
                    :maxlength="11"
                  />
                </div>
              </div>

              <!-- 服務資料 -->
              <div class="form_description">
                <div class="main_description_box mg-tb-10">
                  <h4 class="main_description_title txt-light_green txt-bold">服務資料</h4>
                </div>
              </div>
              <div class="form_interactive">
                <TextInput
                  class="w50"
                  v-model.trim="user.department"
                  :item="inputFormat.departmentInput"
                  :rules="[
                    val => !!val || '必填欄位！',
                    val => val.length > 1 || '長度錯誤!',
                  ]"
                />
                <TextInput
                  class="w50"
                  v-model.trim="user.hospital"
                  :item="inputFormat.hospitalInput"
                  :rules="[
                    val => !!val || '必填欄位！',
                    val => val.length > 1 || '長度錯誤!',
                  ]"
                />
                <div class="select_container w100">
                  <p><span class="txt-red txt-regular star_hidden">*</span>院所地址</p>
                  <div class="select_container-left err_message_box w50">
                    <span class="err_message txt-red small">{{ hospitalAddressErrorMsg }}</span>
                    <select
                      v-model="user.addressHospital.town"
                      class="select_city address_top w33"
                      @change="resetHospitalSelect"
                    >
                      <option value="" selected>請選擇</option>
                      <option
                        v-for="(town, index) in hospitalTowns"
                        :key="index"
                        :value="town"
                      >{{ town }}
                      </option>
                    </select>
                    <select
                      v-model="user.addressHospital.area"
                      class="select_city address_top w33"
                      @change="updateHospitalZip"
                    >
                      <option value="" selected>請選擇</option>
                      <option
                        v-for="(area, index) in hospitalAreas"
                        :key="index"
                        :value="area"
                      >{{ area }}
                      </option>
                    </select>
                    <input
                      :value="user.addressHospital.zip"
                      type="text"
                      readonly
                      class="address_top w33"
                      placeholder="郵遞區號"
                    >
                  </div>
                  <div class="select_container-right w50">
                    <TextInput
                      class="address_top"
                      v-model.trim="user.addressHospital.detail"
                      :item="inputFormat.addressHospitalDetailInput"
                    />
                  </div>
                </div>
              </div>

              <!-- 必要條件 -->
              <div class="form_description">
                <div class="main_description_box mg-tb-10">
                  <h4 class="main_description_title txt-light_green txt-bold">必要條件</h4>
                  <ul class="number_list">
                    <li><p>具本會有效會員資格</p></li>
                    <li><p>具任何科別之專科醫師證照</p></li>
                    <li><p>無專科醫師者，需執業登記滿</p></li>
                  </ul>
                  <p class="paper_clip">
                    需提供 <strong class="txt-dark_green">專科醫師證書影本
                    </strong> 或 <strong class="txt-dark_green">醫師執照正反面影本
                    </strong> (格式 jpg/png/pdf。限制大小：1MB)
                  </p>
                </div>
              </div>
              <div class="form_interactive">
                <Upload
                  class="w100"
                  v-model="user.upload.professionCertifyCopy"
                  :item="inputFormat.professionCertifyCopyUpload"
                  :rules="[
                    val => !!val || '請上傳檔案!',
                  ]"
                />
                <Upload
                  class="w50"
                  v-model="user.upload.doctorCertifyFront"
                  :item="inputFormat.doctorCertifyFrontUpload"
                  :rules="[
                    val => !!val || '請上傳檔案!',
                  ]"
                />
                <Upload
                  class="w50"
                  v-model="user.upload.doctorCertifyBack"
                  :item="inputFormat.doctorCertifyBackUpload"
                  :rules="[
                    val => !!val || '請上傳檔案!',
                  ]"
                />
              </div>

              <div class="form_description">
                <div class="main_description_box mg-tb-10">
                  <h4 class="main_description_title txt-light_green txt-bold">本醫學會同意認可之專業60學分課程</h4>
                  <p class="paper_clip">
                    需提供 <strong class="txt-dark_green">學分上課時數證明
                    </strong> (格式 jpg/png/pdf。限制大小：1MB)
                  </p>
                  <p class="reminder small txt-red">※ 110年12月31日前可免筆試；111年1月1日後申請需達筆試及格</p>
                </div>
              </div>
              <div class="form_interactive">
                <Upload
                  class="w100"
                  v-model="user.upload.creditProof"
                  :item="inputFormat.creditProofUpload"
                  :rules="[
                    val => !!val || '請上傳檔案!',
                  ]"
                />
              </div>

              <div class="form_submit txt-center">
                <button
                  type="button"
                  class="btn p"
                  @click="onSubmit"
                >送出</button>
              </div>

            </form>
          </div>

        </div>
      </section>
    </main>

    <ModalSuccess
      :isDataValid="isDataValid"
      :modalItem="modalItem"
      @closeSuccessModal="closeSuccessModal"
    />
  </div>
</template>

<script>
import cities from '@/data/cities.json';
import { AccountApplySubHeader } from '@/lib/const';
import PageSubHeader from '@/components/PageSubHeader.vue';
import TextInput from '@/components/form/TextInput.vue';
import Upload from '@/components/form/Upload.vue';
import ModalSuccess from '@/components/ModalSuccess.vue';
import { request, toFormData } from '@/boot/axios';

export default {
  name: 'AccountApplyProfession',
  data() {
    return {
      ...AccountApplySubHeader,
      hospitalAddressErrorMsg: '',
      isDataValid: false,
      modalItem: {
        content: '已收到您的申請資料',
        btnText: '確認',
      },
      user: {
        name: '王小明',
        memberId: '009',
        gender: '男',
        birthday: '2020-10-20',
        id: 'A123456789',
        mobile: '',
        telePublic: '',
        teleHome: '',
        department: '',
        hospital: '',
        addressHospital: {
          town: '',
          area: '',
          zip: '',
          detail: '',
        },
        upload: {
          professionCertifyCopy: '',
          doctorCertifyFront: '',
          doctorCertifyBack: '',
          creditProof: '',
        },
      },
      inputFormat: {
        mobileInput: {
          label: '手機號碼',
          type: 'text',
          placeholder: '請輸入手機號碼',
          required: true,
        },
        telePublicInput: {
          label: '電話(公)',
          type: 'text',
          placeholder: '02-12345678',
          required: false,
        },
        teleHomeInput: {
          label: '電話(家)',
          type: 'text',
          placeholder: '02-12345678',
          required: false,
        },
        departmentInput: {
          label: '科別',
          type: 'text',
          placeholder: '請輸入完整名稱',
          required: true,
        },
        hospitalInput: {
          label: '院所名稱(例如：台灣居家醫療醫學會)',
          type: 'text',
          placeholder: '請輸入完整名稱',
          required: true,
        },
        addressHospitalDetailInput: {
          label: '',
          type: 'text',
          placeholder: '請填寫院所地址',
          required: false,
        },
        professionCertifyCopyUpload: {
          label: '專科醫師證書影本',
          reminder: '',
          accept: '.jpg,.png,.pdf',
          required: true,
        },
        doctorCertifyFrontUpload: {
          label: '醫師證書-正面',
          reminder: '',
          accept: '.jpg,.png,.pdf',
          required: true,
        },
        doctorCertifyBackUpload: {
          label: '醫師證書-反面',
          reminder: '',
          accept: '.jpg,.png,.pdf',
          required: true,
        },
        creditProofUpload: {
          label: '學分上課時數證明',
          reminder: '',
          accept: '.jpg,.png,.pdf',
          required: true,
        },
      },
    };
  },
  created() {
    // request({
    //   url: 'API',
    //   method: 'get',
    // }).then((result) => {
    //   this.user = result.user;
    // });
  },
  computed: {
    hospitalTowns() {
      return cities.map((city) => city.name);
    },
    hospitalAreas() {
      const hospitalTownIndex = this.hospitalTowns.indexOf(this.user.addressHospital.town);
      if (hospitalTownIndex >= 0) {
        return cities[hospitalTownIndex].areas.map((area) => area.name);
      }
      return [];
    },
  },
  methods: {
    closeSuccessModal() {
      this.isDataValid = false;
    },
    updateHospitalZip() {
      const hospitalTownIndex = this.hospitalTowns.indexOf(this.user.addressHospital.town);
      const hospitalAreaIndex = this.hospitalAreas.indexOf(this.user.addressHospital.area);
      if (hospitalAreaIndex >= 0) {
        this.user.addressHospital.zip = cities[hospitalTownIndex].areas[hospitalAreaIndex].zip;
      }
      if (hospitalAreaIndex === -1) {
        this.user.addressHospital.zip = '';
      }
      // this.addressHospitalTest();
    },
    resetHospitalSelect() {
      this.user.addressHospital.area = '';
      this.user.addressHospital.zip = '';
      // this.addressHospitalTest();
    },
    // addressHospitalTest() {
    //   if (!this.user.addressHospital.town || !this.user.addressHospital.area) {
    //     this.hospitalAddressErrorMsg = '必選欄位！請選擇縣市及區域！';
    //   }
    //   if (this.user.addressHospital.town && this.user.addressHospital.area) {
    //     this.hospitalAddressErrorMsg = '';
    //   }
    // },
    onSubmit() {
      const errorList = [];
      this.$children.forEach((item) => {
        if (item.$options.name && item.$options.name.match(/^Form/i)) {
          item.test();
          errorList.push(item.error);
        }
      });
      if (errorList.indexOf(true) === -1) {
        request({
          url: 'API',
          method: 'post',
          data: toFormData(this.user),
        });
        // axios.post('https://my-json-server.typicode.com/typicode/demo/posts', this.user)
        //   .then((res) => {
        //     console.log('success', res.data);
        //     this.isDataValid = true;
        //   })
        //   .catch((error) => {
        //     console.log(error);
        //   });
      } else {
        // console.log('必填欄位尚未完成！');
      }
    },
  },
  components: {
    PageSubHeader,
    TextInput,
    Upload,
    ModalSuccess,
  },
};
</script>
